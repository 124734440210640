.disabled-overlay {
  pointer-events: none; /* Prevents clicking any buttons inside the div */
  opacity: 0.5; /* Makes the div look faded, like a disabled state */
  position: relative; /* Needed for the correct positioning of the overlay */
}

.disabled-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5); /* White semi-transparent overlay */
  pointer-events: none; /* Prevents clicks on the overlay */
}
