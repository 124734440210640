@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
@media (max-width: 640px) {
  /* Assuming 'sm' is 640px */
  body.no-scrollbar {
    overflow-y: hidden;
  }
}
body {
  height: 100%;
  background-color: #e5e7eb;
  box-sizing: border-box;
}
html,
body,
#root,
.App {
  height: 100%;
}

#root {
  position: relative;
}

.loader * {
  background: var(--theme-primary) !important;
}
.sidebar-icon:hover svg {
  color: var(--theme-primary) !important;
}
.custom-color-picker {
  visibility: hidden;
  height: 1px !important;
  position: absolute;
}
@media print {
  * {
    color: black !important;
  }
  .print-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .print-body > div {
    max-width: 384px;
  }
  .print-body {
    color: inherit;
  }
}

.buged-span {
  margin: 0px !important;
}

.client-logo {
  height: 33px;
}

.switch-client-logo {
  max-width: 60px;
  width: 100%;
}

.payment-scheme {
  height: 20px;
}

.client-logo-homepage {
  height: 75px;
}

.number-place-holder {
  direction: ltr !important ;
}

.half-screen-sheet {
  border-radius: 20px 20px 0 0;
  box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 10px;
  margin: 10px 10px -5px 10px;
  padding-bottom: 10px;
}
@media screen and (max-width: 700px) {
  .half-screen-sheet {
    border-radius: 20px 20px 0 0;
    box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 10px;
    margin: 0px;
  }
}

.client-logo-landing-page {
  width: 270px;
}

.landing-page {
  max-width: 700px;
}

/* ---Sidebar css */
/* .text-primary {
  color: #5a8dee !important;
} */
.btn-primary {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}
.shadow-md {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar {
  border-radius: 5px;
}
.sidebar {
  width: 80vw;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #fff;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 101;
  transition: 0.5s;
}

.sidebar.active {
  left: 0;
}
body[dir="rtl"] .sidebar {
  left: unset;
  right: -100%;
}
body[dir="rtl"] .sidebar.active {
  left: unset;
  right: 0;
}
.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
}
.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}
.sd-body {
  padding: 15px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
}
.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}
.sd-body ul li {
  list-style: none;
  margin-bottom: 8px;
}
.sd-body ul li .sd-link {
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  color: #475f7b;
  background-color: #e5e8ec;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.react-datepicker__input-container input {
  @apply block w-full text-base md:text-sm bg-white border border-gray-300 rounded-md h-full;
  direction: rtl;
}

.react-datepicker-popper {
  @apply z-40 w-72 text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded cursor-pointer;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-400;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-100;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__close-icon {
  opacity: 0.3;
}

.react-datepicker__close-icon:hover {
  opacity: 1;
}

.react-datepicker__close-icon:before,
.react-datepicker__close-icon:after {
  position: absolute;
  top: 0%;
  content: " ";
  height: 17px;
  width: 2px;
  background-color: #333;
}
.react-datepicker__close-icon:before {
  transform: rotate(45deg);
}
.react-datepicker__close-icon:after {
  transform: rotate(-45deg);
}

.query-builder {
  direction: ltr !important;
}

.ltr {
  direction: ltr !important;
}

.inner-performance {
  display: inline-block;
  height: 100%;
  transition: all 0.1s;
  position: relative;
  border: solid 2px transparent;
}

.inner-performance:hover {
  z-index: 9999;
  border: solid 2px rgb(29, 73, 186);
}
.inner-performance:hover::after {
  position: absolute;
  content: attr(data-title);
  border-radius: 15px;
  padding: 5px 10px;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #eee;
}
.inner-performance:first-child {
  border-radius: 11px 0 0 11px;
}
.inner-performance:last-child {
  border-radius: 0 11px 11px 0;
}

.inner-performance-no-redius:first-child {
  border-radius: 0;
}
.inner-performance-no-redius:last-child {
  border-radius: 0;
}

.public-container .gradient {
  background-image: linear-gradient(to left, #c0b5f1 60%, #dcc5e0);
  box-shadow: 0px 0 10px inset #999;
}

.public-container .gradient .hero-image {
  height: 100vh !important;
  width: 100% !important;
  margin-right: -20% !important;
  position: absolute;
  right: 0;
}

.preserve-padding:empty::before {
  content: "\200B";
}

details summary::-webkit-details-marker {
  display: none;
}
.tooltip {
  @apply relative before:absolute before:bottom-full ltr:before:-translate-x-1/3 rtl:before:translate-x-1/3 before:-translate-y-1/4  before:rounded before:bg-gray-400 before:px-2.5 before:py-1 before:capitalize before:text-white before:content-[attr(data-tip)] before:text-xs before:hidden  hover:before:block transition duration-150 ease-in-out before:whitespace-nowrap before:z-10;
}

.rounded-tremor-small > svg,
.recharts-layer,
.recharts-bar-rectangle {
  fill: black;
}
/* .recharts-layer :hover,
.recharts-bar-rectangle :hover,
recharts-bar-rectangle rect :hover {
  fill: black;

  opacity: 95%;
} */
.hide-scrollbar {
  /* For Internet Explorer, Edge */
  -ms-overflow-style: none;

  /* For Firefox */
  scrollbar-width: none;

  /* For Chrome, Safari, Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.intercom-dfosxs.e2u51n60 {
  translate: 0 -40px;
}
.calculate-content {
  @media (min-width: 640px) {
    max-width: calc(100vw - 4.7rem);
  }
}
.calculate-height {
  height: 92vh;
  @media (min-width: 640px) {
    height: calc(92vh - 8px);
  }
}

.saparate-table table {
  border-spacing: 0;
  border-collapse: separate !important;
}

.propagate-border td {
  border-bottom-width: 1px;
}
.propagate-header th {
  border-bottom-width: 1px;
}
tr:last-child td {
  border-bottom-width: 0;
}
.calculate-loader {
  height: calc(100vh - 10rem);
}
