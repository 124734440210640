@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --accent-primary: black; /* Replace with your default color */
}
select {
  background-image: none !important;
}

.paginator {
  direction: ltr;
}
.tremor-Legend-legendItem svg {
  margin-left: 5px;
}
.tremor-DateRangePicker-calendarIcon {
  margin-left: 5px;
}
